/* index.css or index.js */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Set the background color */
html {
  background-color: #202124;
  color: #fff;
}

html,
body,
#root, /* Assuming #root is the root element of your React app */
.app-container {
  /* Add any other containers if needed */
  overflow: hidden;
}
